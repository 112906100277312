<template>
  <section class="engineering-works">
    <div class="engineering-works__wrp">
      <div class="engineering-works__content">
        <h3 class="engineering-works__title">Ведуться технічні роботи</h3>

        <div class="engineering-works__clue">
          <p class="engineering-works__text">
            На даний момент сайт недоступний, просимо вибачення
          </p>
          <p v-if="null !== getMessage" class="engineering-works__text">
            {{ getMessage }}
          </p>
          <p class="engineering-works__text">
            Для придбання товару або консультації можна звертатися за номером
            телефону:
          </p>
        </div>

        <a class="engineering-works__btn" href="tel:0800218573">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              class="engineering-works__btn-icon"
              d="M18.1183 14.702L14.0001 15.5C11.2184 14.1038 9.50009 12.5 8.50009 10L9.27004 5.8699L7.81461 2L4.06369 2C2.93614 2 2.04823 2.93178 2.21663 4.04668C2.63704 6.83 3.87662 11.8765 7.50009 15.5C11.3053 19.3052 16.7858 20.9564 19.8021 21.6127C20.9669 21.8662 22.0001 20.9575 22.0001 19.7655L22.0001 16.1812L18.1183 14.702Z"
              stroke="#F36C21"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>0 800 218 573</span>
        </a>

        <div class="engineering-works__social">
          <p class="engineering-works__social-title">Нашi соцiальнi мережi</p>
          <div class="engineering-works__social-networks">
            <a
              :href="socials.facebook"
              class="engineering-works__social-item"
              target="_blank"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 2H14C12.6739 2 11.4021 2.52678 10.4645 3.46447C9.52678 4.40215 9 5.67392 9 7V10H6V14H9V22H13V14H16L17 10H13V7C13 6.73478 13.1054 6.48043 13.2929 6.29289C13.4804 6.10536 13.7348 6 14 6H17V2Z"
                  stroke="#1F1F1F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>

            <a
              :href="socials.youtube"
              class="engineering-works__social-item"
              target="_blank"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 12L10.5 14V10L14 12Z"
                  fill="#1F1F1F"
                  stroke="#1F1F1F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2 12.708V11.2929C2 8.39754 2 6.94988 2.90549 6.01841C3.81099 5.08694 5.23656 5.04662 8.08769 4.96598C9.43873 4.92777 10.8188 4.90039 12 4.90039C13.1812 4.90039 14.5613 4.92777 15.9123 4.96598C18.7634 5.04662 20.189 5.08694 21.0945 6.01841C22 6.94988 22 8.39754 22 11.2929V12.708C22 15.6033 22 17.0509 21.0945 17.9824C20.189 18.9139 18.7635 18.9542 15.9124 19.0349C14.5613 19.0731 13.1812 19.1005 12 19.1005C10.8188 19.1005 9.43867 19.0731 8.0876 19.0349C5.23651 18.9542 3.81097 18.9139 2.90548 17.9824C2 17.0509 2 15.6033 2 12.708Z"
                  stroke="#1F1F1F"
                  stroke-width="1.5"
                />
              </svg>
            </a>

            <a
              :href="socials.instagram"
              class="engineering-works__social-item"
              target="_blank"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
                  stroke="#1F1F1F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 16V8C3 5.23858 5.23858 3 8 3H16C18.7614 3 21 5.23858 21 8V16C21 18.7614 18.7614 21 16 21H8C5.23858 21 3 18.7614 3 16Z"
                  stroke="#1F1F1F"
                  stroke-width="1.5"
                />
                <path
                  d="M17.5 6.51L17.51 6.49889"
                  stroke="#1F1F1F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>

            <a
              :href="socials.telegram"
              class="engineering-works__social-item"
              target="_blank"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 5L2 12.5L9 13.5M21 5L18.5 20L9 13.5M21 5L9 13.5M9 13.5V19L12.2488 15.7229"
                  stroke="#1F1F1F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>
        </div>

        <div class="engineering-works__img-wrp">
          <img src="/img/engineering.png" alt="cat" />
          <h3 class="engineering-works__img-title">
            Ми маємо технічні роботи, але вже скоро ми будемо з вами
          </h3>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useMaintenanceStore } from "~~/store/maintenanceStore";

definePageMeta({
  layout: false,
});

const maintenanceStore = useMaintenanceStore();
const { getIsEnabled, getMessage } = storeToRefs(maintenanceStore);

const interval = setInterval(async () => {
  maintenanceStore.fetch();

  watch(getIsEnabled, () => {
    if (!getIsEnabled.value) {
      clearInterval(interval);

      window.location.reload();
    }
  });
}, 5000);
</script>

<style lang="scss" scoped>
.engineering-works {
  padding: 64px 16px 100px;

  @include bigMobile {
    background-color: white;

    padding: 24px 16px;
  }

  &__wrp {
    @extend %width-main;

    @include flex-container(row, space-between, center);

    gap: 24px;
    margin: 0 auto;

    @include bigMobile {
      @include flex-container(column-reverse, space-between, center);
    }
  }

  &__content {
    max-width: 468px;
    width: 100%;

    @include flex-container(column, space-between, center);
    gap: 24px;

    @include bigMobile {
      max-width: 100%;
      text-align: center;

      gap: 16px;
    }
  }

  &__title {
    @include font(24, 34, 700);
    color: #2b2b2b;
    letter-spacing: 0.02em;

    @include bigMobile {
      @include font(20, 28, 700);
    }
  }

  &__clue {
    @include flex-container(column, space-between);

    gap: 8px;

    @include bigMobile {
      gap: 16px;
    }
  }

  &__text {
    @include font();
    color: #2b2b2b;
    letter-spacing: 0.02em;
  }

  &__btn {
    width: max-content;

    @include flex-container(row, space-evenly, center);
    gap: 16px;

    border: 1px solid var(--color-primary-base);
    background-color: var(--color-primary-base);
    border-radius: 8px;

    padding: 8px 32px;

    transition:
      border-color 0.1s,
      background-color 0.1s;

    @include mobile {
      width: 100%;

      @include flex-container(row, center, center);
    }

    &:hover {
      border-color: #ff5b00;
      background-color: #ff5b00;

      & span {
        color: white;
      }

      .engineering-works__btn-icon {
        stroke: white;
      }
    }

    &:active {
      border-color: #d8570f;
      background-color: #d8570f;

      & span {
        color: white;
      }

      .engineering-works__btn-icon {
        stroke: white;
      }
    }

    & span {
      @include font(18, 24);
      letter-spacing: 0.02em;

      color: white;

      transition: color 0.1s ease;
    }

    .engineering-works__btn-icon {
      stroke: white;
    }
  }

  &__social {
    @include flex-container(column, space-between, center);

    gap: 16px;

    @include bigMobile {
      gap: 8px;
    }
  }

  &__social-title {
    @include font(18, 24, 500);
    letter-spacing: 0.02em;

    @include bigMobile {
      color: var(--color-primary-base);
    }
  }

  &__social-networks {
    max-width: 168px;
    width: 100%;

    @include flex-container(row, space-between, center);

    gap: 8px;

    @include bigMobile {
      max-width: 100%;

      justify-content: space-around;
    }
  }

  &__social-item {
    font-size: 0;
  }

  &__img-wrp {
    max-width: 654px;
    width: 100%;

    @include flex-container(column, null);

    text-align: center;

    gap: 8px;

    @include bigMobile {
      max-width: 420px;
    }

    @include mobile {
      max-width: 220px;
    }
  }

  &__img-title {
    @include font(36, 44, 700);
    color: #2b2b2b;
    letter-spacing: 0.02em;

    @include bigMobile {
      @include font(16, 24, 700);
    }

    @include mobile {
      @include font(12, 16, 700);
    }
  }
}
</style>
